import clsx from 'clsx';
import styles from './Steper.module.scss';

export type SteperStep = {
  title: string;
}

type SteperProps = {
  steps: SteperStep[];
  current: number;
}

export const Steper = (props: SteperProps) => {
  const { steps, current } = props;

  return (
    <div className={styles.container}>
      {steps.map((step, idx) => (
        <div key={idx} className={styles.stepContainer}> 
          <div className={clsx(
            styles.step,
            idx < current ? styles.stepPassed : undefined,
            idx == current ? styles.stepCurrent : undefined,
            idx > current ? styles.stepFutured : undefined,
          )}>
            <div>{idx + 1}</div>
            <p>{step.title}</p>
          </div>
          {idx < steps.length - 1 ? (
            <div className={styles.line} />
          ) : null}
        </div>
      ))}
    </div>
  );
};
