import { useRouter } from "next/router";

const PARAM_NAME = 'ref';

export const useReferalLink = (): [string | null, () => void] => {
  const router = useRouter();

  let referalId = router.query[PARAM_NAME] as string || null;

  if (typeof window !== "undefined") {
    if (referalId) {
      window.localStorage.setItem(PARAM_NAME, referalId);
      window.history.replaceState(null, '', window.location.href.split('?')[0]);
    } else {
      referalId = window.localStorage.getItem(PARAM_NAME);
    }
  }

  const clear = () => localStorage.removeItem(PARAM_NAME);

  return [referalId, clear];

};
